import React from "react"
// import FreeTrialArea from '../components/Common/FreeTrialArea';
import Layout from "../components/layout"
import logo from "../images/logo-70px.png"
import { Link } from "gatsby"

export default function NotFound() {
  return (
    <Layout>
      <div className="text-center">
        <img src={logo} alt="logo" />
        <h1>404</h1>
        <p>We're sorry, but we couldn't find that page</p>
        <Link to="/" className="default-btn">
          <i className="bx bx-arrow-from-right"></i>
          Back Home
          <span></span>
        </Link>
      </div>
    </Layout>
  )
}
